import DayCounter from './DayCounter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTiktok, faFacebook } from '@fortawesome/free-brands-svg-icons';
import logo from '../../media/logo.png';

export default function MainContent() {
  return (
    <div className="relative py-40">
      <div className="container mx-auto flex justify-center items-center space-x-0 relative z-10">
        <div className="text-white p-4 animate__animated animate__fadeInLeft animate__delay-[0.3s]">
          <h2 className="text-4xl font-bold mb-4 text-left drop-shadow-xl">A MinePlanet <br></br>megszűnt. 😭</h2>
          <p className="text-lg text-gray-400 mb-4 leading-snug text-left drop-shadow-xl">Köszönjük azt, hogy a nyitás óta eltelt <br></br><DayCounter /> nap során ennyien velünk voltatok!</p>
        </div>
        
        <div className="pr-6 pl-10 z-10 animate__animated animate__fadeInRight animate__delay-[0.3s] drop-shadow-[0_0_100px_rgba(38,59,107,0.5)]">
          <div className="hidden xl:block">
            <img src={logo} alt="" width="300" className="min-w-full relative z-20 transform scale-90 xl:scale-100" />
          </div>
        </div>
      </div>
    </div>
  );
}