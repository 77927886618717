import config from '../../config.json';

export default function Footer() {
  return (
    <footer className="bg-black text-white py-8 px-10 xl:px-10">
      <div className="text-center">
        <p className='text-sm'>Ez a weboldal teljesen független, a korábban a <span className="text-blue-200">{config.domain}</span> weboldalt üzemeltető <span className="text-blue-200">Tele Service Korlátolt Felelősségű Társaságtól</span>.</p>
        <p className='text-sm'>E-mail: <span className="text-blue-200">ended@mineplanet.hu</span></p>
        <p className="text-sm">{config.domain} &copy; 2022 - {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}