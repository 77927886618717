import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/stylesheet.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'animate.css';
import App from './App';

const root = document.getElementById('root');
const reactRoot = createRoot(root);

reactRoot.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
