import { useEffect, useState } from 'react';

export default function DayCounter() {
  const [dayCount, setDayCount] = useState(0);

  useEffect(() => {
    const startDate = new Date('2022-04-13');
    const currentDate = new Date();
    const timeDifference = currentDate - startDate;
    const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    setDayCount(daysElapsed);
  }, []);

  return (
    <span className="text-blue-400">{dayCount}</span>
  );
}