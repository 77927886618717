import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUser, faRightToBracket, faUpRightFromSquare, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faTiktok, faFacebook, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import asd from '../../halloffame.json';


export default function HallOfFame() {

  return (
    <div className="container mx-auto px-4 sm:px-52 py-[80px] text-center">
      <div className="max-w-3xl mx-auto animate__animated animate__fadeInDown animate__delay-[0.3s]">
        <h1 className="text-4xl text-white font-bold">Dicsőségfal</h1>
        <p className="text-gray-400 mt-2">Ők dolgoztak azért, hogy a MinePlanet egy király hely legyen minden nap.</p>
        <p className="text-gray-500 text-xs">(ha szeretnél közösségi média linkedet közzétenni a saját faladra, írj nekünk egy levelet az oldal alján található e-mail címre)</p>
      </div>
      <div className="mt-10 grid grid-cols-1 xl:grid-cols-3 gap-10">
      {asd.map((qa, index) => (
        <div className="bg-slate-800 border-slate-700 border p-6 rounded-xl shadow-md animate__animated animate__fadeInDown animate__delay-[0.3s]">
          <div class="flex justify-center items-center">
            <img src={`https://mineskin.eu/armor/body/${qa.skin}/100.png`} alt={`${qa.name} skin`} />
          </div>
          <h2 className="text-2xl font-semibold text-white text-center mt-3">{qa.crowned === 'true' && <FontAwesomeIcon className="pr-1 text-sky-600" icon={faCrown} />} {qa.name}</h2>
          <p className={`${qa.rankcolor} text-center`}>{qa.rank}</p>
          <p className="text-center text-gray-300 mt-3">{qa.lore}</p>
          <div className="flex justify-center items-center">
          {qa.fb && (
            <a href={qa.fb} className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full"><FontAwesomeIcon icon={faFacebook} /></a>
          )}
          {qa.dc && (
            <a href={qa.dc} className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full"><FontAwesomeIcon icon={faDiscord} /></a>
          )}
          {qa.tiktok && (
            <a href={qa.tiktok} className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full"><FontAwesomeIcon icon={faTiktok} /></a>
          )}
          {qa.instagram && (
            <a href={qa.instagram} className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full"><FontAwesomeIcon icon={faInstagram} /></a>
          )}
          {qa.xTwitter && (
            <a href={qa.xTwitter} className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full"><FontAwesomeIcon icon={faXTwitter} /></a>
          )}
          </div>
        </div>
        ))}
        </div>
        </div>
  );
}