import React from 'react';

import MainContent from './components/MainContent/MainContent';
import HallOfFame from './components/HallOfFame/HallOfFame';
import FAQ from './components/Faq/Faq';
import Footer from './components/Footer/Footer';

export default function() {
  return (
    <div className="bg-slate-900">
      <MainContent />
      <HallOfFame />
      <FAQ />
      <Footer />
    </div>
  );
}