import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import asd from '../../faq.json';


export default function Faq() {
  const [expanded, setExpanded] = React.useState(null);

  const toggleAccordion = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  return (
    <div className="py-20">
      <div className="container mx-auto px-4 bg-slate-800 rounded-lg text-white max-w-6xl">
        {asd.map((qa, index) => (
          <div key={index} className="mb-4 flex flex-col">
            <div className={`flex items-center justify-between cursor-pointer p-4 border-b-0 transition duration-300 ease-in-out transform`} onClick={() => toggleAccordion(index)}>
              <div className="flex items-center space-x-8">
                <div className="text-lg"><FontAwesomeIcon icon={faCircleQuestion} className="text-sky-600 text-lg" /> {qa.kerdes}</div>
              </div>
              <FontAwesomeIcon icon={expanded === index ? faChevronUp : faChevronDown} className="text-white text-2xl" />
            </div>
            {expanded === index && (
              <div>
                <div className="p-4 text-slate-400 pl-7">{qa.valasz}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}